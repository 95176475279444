body,
* {
  margin: 0;
  padding: 0;
  font-size: 10px;
  box-sizing: border-box;
  line-height: 1.6;
}

@font-face {
  font-family: DIN_Normal_light;
  src: url("./assets/Fonts/DIN_Pro_Font_Family_Fontmirror/DIN\ Pro\ Light\ 300.otf");
}

@font-face {
  font-family: DIN_Normal_condenced;
  src: url("./assets/Fonts/DIN_Pro_Font_Family_Fontmirror/DINPro-Light\ tr.ttf");
}

@font-face {
  font-family: DIN_Italic_Normal;
  src: url("./assets/Fonts/DIN_Pro_Font_Family_Fontmirror/DIN\ Pro\ Italic\ 400.otf");
}

@font-face {
  font-family: DIN_Italic_Bold;
  src: url("./assets/Fonts/DIN_Pro_Font_Family_Fontmirror/DIN\ Pro\ Bold\ Italic\ 700.otf");
}
@font-face {
  font-family: DIN_Italic_Med;
  src: url("./assets/Fonts/DIN_Pro_Font_Family_Fontmirror/DIN\ Pro\ Bold\ 700.otf");
}

/*Gotham font*/
@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-XLight_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-XLight_Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-XLightItalic_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-XLightItalic_Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-Light_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-Light_Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-LightItalic_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-LightItalic_Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-Book_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-Book_Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-BookItalic_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-BookItalic_Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-Medium_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-Medium_Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-MediumItalic_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-MediumItalic_Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-Bold_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-Bold_Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-BoldItalic_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-BoldItalic_Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-Black_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-Black_Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "HCo Gotham SSm";
  src: url("./assets/Fonts/GothamSSm-BlackItalic_Web.woff2") format("woff2"),
    url("./assets/Fonts/GothamSSm-BlackItalic_Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}
/*Finish Gotham Font*/

h1 {
  font-family: DIN_Italic_Bold;
  font-size: 5rem;
}

h2 {
  font-size: 3rem;
  font-family: DIN_Italic_Bold;
}

h3 {
  font-size: 2.4rem;
  font-family: DIN_Italic_Bold;
}

p,
a {
  font-size: 1.4rem;
  font-family: DIN_Italic_Normal;
}

@media (min-width: 576px) {
  h2 {
    font-size: 3.4rem;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 24px !important;
  }

  h2 {
    font-size: 4rem;
  }

  p {
    font-size: 1.8rem !important;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

/* * {
  border: 1px solid red;
} */

.simple-button-dekalb {
  width: 112px;
  height: 34px;
  background: #ffd22b;
  border-radius: 5px;
  font-weight: bold;
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  border: 0;
}

.simple-textbox-dakalb {
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 34px;

  text-align: center;

  background: #f0f0f0;
  border-radius: 5px;
  border: 0;
  width: 100%;
}

.comentarios_carrusel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(255, 210, 43)' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
  height: 30px !important;
}
.comentarios_carrusel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(255, 210, 43)' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
  height: 30px !important;
}
/* 
* {
  border: 1px solid red;
} */
